.row-agenda{
    display: flex;
}
.col-agenda{
    grid-auto-rows: 1fr;
    padding:2%;
}
.card-agenda {
    height:100% !important;
    width: 100% !important;
    display: grid;
    align-items: center;
}
