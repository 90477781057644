.row-agenda{
    display: flex;
}
.card-foto {
    width: 100% !important;
}
 .card-footer {
    background-color: transparent  !important;
 }
 .card {
    border: 1px solid transparent !important;
    margin-bottom: 35px !important;
 }
 
.row-contacto div a {
    color: black;
}

.row-contacto div {
    padding: 16px;
}

.row-contacto {
    cursor: pointer;
    transition: all 0.5s linear;
}

.row-contacto:hover {
    background-color: #1e3d52;
    border-radius: 32px;
}

.row-contacto:hover div {
    color: white;
}

.row-contacto:hover div svg {
    width: 60px;
    height: 60px;
}
